import '../../styles/footer/Footer.css'
import FooterBottom from './FooterBottom';
import FooterTop from "./FooterTop";

export default function Footer(){
    return (
    <footer>
        
        <FooterBottom/>
    </footer>
    );
}